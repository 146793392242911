import { memo, type FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
// Local
import BooksGrid from './BooksGrid';
import BookPage from './BookPage';

const Reader: FunctionComponent = () => {
  const { religion, bookId } = useParams();

  return (
    <>
      {(bookId && (
        <BookPage bookId={bookId}/>
      )) || (
        <BooksGrid religion={religion}/>
      )}
    </>
  );
};

export default memo(Reader);
