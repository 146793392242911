import { memo, type FunctionComponent } from 'react';
// Local imports
import Screen from '../components/Screen';
import BookReaderPanel from '../Panels/BookReaderPanel';

const BookReader: FunctionComponent = () => {
  return (
    <Screen>
      <BookReaderPanel/>
    </Screen>
  );
}

// Default export
export default memo(BookReader);
