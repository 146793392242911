import { memo, useEffect, type ReactNode, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
// MUI
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
// local imports
import { GUEST_AUTH_MUTATION } from '../graphql/GuestAuth';
import { GuestAuthMutation, GuestAuthDocument } from '../graphql/types';
import FetchFailedAlert from './FetchFailedAlert';
import { isLoggedIn } from '../helpers/user';

type ScreenProps = {
  testPending?: boolean,
  testFailed?: boolean,
  children?: ReactNode | ReactNode[];
};

const ScreenPropTypes = {
  testPending: PropTypes.bool,
  testFailed: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

const Screen: FunctionComponent<ScreenProps> = ({
  testPending,
  testFailed,
  children
}) => {
  const [guestAuth, { loading, error }] = useMutation(GUEST_AUTH_MUTATION as typeof GuestAuthDocument);

  useEffect(() => {
    if (!isLoggedIn() && guestAuth) {
      guestAuth({
        variables: { input: { empty: '' } },
        onCompleted: (data: GuestAuthMutation) => {
          const token = data.guestAuth.data.tokens.access_token
          if (token) {
            localStorage.setItem('token', token);
            localStorage.setItem('role', 'guest');
          }
        }
      });
    }
  }, [guestAuth]);

  return (
    <>
      {((loading || testPending) && (
        <Box sx={{width: '100%'}}>
          <LinearProgress/>
        </Box>
      )) || ((error || testFailed) && (
          <FetchFailedAlert/>
      )) || (isLoggedIn() && (
        children
      ))}
    </>
  );
};

Screen.propTypes = ScreenPropTypes;

export default memo(Screen);
