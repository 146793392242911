import {memo, useEffect, type FunctionComponent} from 'react';
import { useParams } from 'react-router-dom';
import toString from 'lodash/toString';
// MUI
import Box from '@mui/material/Box';
// Local imports
import { useApi } from '../context/ApiProvider';
import ListSharedChat from '../components/ListSharedChat';

const SharedChatPanel: FunctionComponent = () => {
  const { chatId, shareId } = useParams();
  const {
    getSharedChat: { getSharedChat, sharedChat, pending, failed }
  } = useApi();

  useEffect(() => {
    if (shareId) getSharedChat?.(toString(chatId), toString(shareId));
  }, [chatId, shareId, getSharedChat]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Main Content Area */}
      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
        <ListSharedChat
          history={sharedChat?.data?.messages}
          createdAt={toString(sharedChat?.data?.created_at)}
          pending={pending}
          failed={failed}
        />
      </Box>
    </Box>
  );
}

// Default export
export default memo(SharedChatPanel);
