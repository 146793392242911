import { memo, type FunctionComponent } from 'react';
// Local imports
import Screen from '../components/Screen';
import VersesPanel from '../Panels/VersesPanel';

const VersesPage: FunctionComponent = () => {
  return (
    <Screen>
      <VersesPanel/>
    </Screen>
  );
}

// Default export
export default memo(VersesPage);
