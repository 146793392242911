import { type SnackbarOrigin } from '@mui/material/Snackbar';

// Layout
export const MIN_DRAWER = 18 as const;
export const MIN_HISTORY = 37 as const;

// Signup
export const MIN_PASSWORD_LENGTH = 6 as const;

// Polling
export const POLLING_INTERVAL = 5000;
export const MAX_POLLING_ITERATIONS = 24;

export const PENDING_STATUS = 'pending';
export const FAILED_STATUS = 'failed';

// Shred link placeholder
export const SHARED_LINK_URL = `https://${process.env.REACT_APP_API_HOST}`;
export const SHARED_LINK_PLACEHOLDER = `${SHARED_LINK_URL}/share/...`;
export const SHARED_VERSE_LINK_URL = `https://${process.env.REACT_APP_API_HOST}`;

// When user types in lookup-select input, only call API at most once per every specified number of milliseconds
export const API_CALL_LOOKUP_WAIT = 500 as const; // milliseconds

export const SNACKBAR_AUTOHIDE_DURATION = 6000 as const; // milliseconds
export const SNACKBAR_ELEVATION = 5 as const; // See: https://material.io/design/environment/elevation.html#default-elevations

export const DEFAULT_QUARTER_FORMAT = 'qQyyyy';

// Pagination
export const PAGE_SIZE_LARGE = '50' as const;

// Titles Truncation
export const MAX_DISPLAYED_TITLE_LENGTH = 36 as const;
export const TITLE_TRUNCATE_OPTIONS = {
  length: MAX_DISPLAYED_TITLE_LENGTH,
  separator: /\s+/u,
  omission: '…'
} as const;

// Snackbar
export const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'center' };

// ECharts
export const GlobalEChartsStyles = {
  textStyle: {
    fontFamily: 'Roboto'
  }
} as const;
