import { memo, useCallback, type MouseEvent, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// MUI
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import Tooltip from '@mui/material/Tooltip';
// Local
import { PATH_CHAT } from '../config/paths';
import { MIN_DRAWER, MIN_HISTORY } from '../config/params';
import { useApi } from '../context/ApiProvider';
import ScripturesSelect from './ScripturesSelect';
import Share from './Share';
import ProfileBtn from './ProfileBtn';
import ShowVersesHeader from './ShowVersesHeader';
import ShowBookHeader from './ShowBookHeader';

type ChatHeaderProps = {
  onSelectScripture?: (scripureId: string) => void;
  pending?: boolean;
  disabled?: boolean;
  showSources?: boolean;
  showShare?: boolean;
  showShareVerse?: boolean;
  showVersesExplorer?: boolean;
  showBookHeader?: boolean;
}

const ChatHeaderPropTypes = {
  onSelectScripture: PropTypes.func,
  pending: PropTypes.bool,
  disabled: PropTypes.bool,
  showSources: PropTypes.bool,
  showShare: PropTypes.bool,
  showShareVerse: PropTypes.bool,
  showVersesExplorer: PropTypes.bool,
  showBookHeader: PropTypes.bool
};

const ChatHeader: FunctionComponent<ChatHeaderProps> = ({
  onSelectScripture,
  pending,
  disabled,
  showSources,
  showShare,
  showShareVerse,
  showVersesExplorer,
  showBookHeader
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getDrawer: { handleToggleDrawer, drawerOn } } = useApi();

  const onNewChat = useCallback(() => {
    navigate(PATH_CHAT);
  }, [navigate]);

  const handleMouseDown = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }, []);

  return (
    <Box sx={{ flexShrink: 0, '& .MuiToolbar-root': { p: 0, pr: 1 } }}>
      <Toolbar sx={{ minWidth: `${(drawerOn ? MIN_DRAWER : 0) + MIN_HISTORY}rem`, p: 0 }}>
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          sx={{
            minWidth: `${drawerOn ? MIN_DRAWER : 0}rem`,
            backgroundColor: `${drawerOn ? '#fafafa' : 'inherent'}`,
            px: 1,
            py: 2
          }}
        >
          <Tooltip title={t(drawerOn ? 'tooltip.hide.drawer' : 'tooltip.show.drawer')} arrow>
            <IconButton onClick={handleToggleDrawer} onMouseDown={handleMouseDown}>
              {drawerOn ? <MenuOpenIcon/> : <MenuIcon/>}
            </IconButton>
          </Tooltip>
          <Box sx={{ flexGrow: 1 }}/>
          <Tooltip title={t('tooltip.new.chat')} arrow>
            <IconButton onClick={onNewChat} onMouseDown={handleMouseDown}>
              <QuestionAnswerOutlinedIcon/>
            </IconButton>
          </Tooltip>
        </Box>
        {showSources && (
          <ScripturesSelect
            disabled={pending || disabled}
            onSelect={onSelectScripture}
          />
        )}
        {showVersesExplorer && <ShowVersesHeader/>}
        {showBookHeader && <ShowBookHeader/>}
        <Box sx={{ flexGrow: 1 }}/>
        {showShare && (<Share/>)}
        {showShareVerse && (<Share verse/>)}
        <ProfileBtn/>
      </Toolbar>
    </Box>
  );
};

ChatHeader.propTypes = ChatHeaderPropTypes;

export default memo(ChatHeader);
