import { memo, type FunctionComponent } from 'react';
import PropTypes, {Validator} from 'prop-types';
// MUI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Slide from '@mui/material/Slide';
// Local
import { MIN_DRAWER } from '../config/params';
import { ChatMessage } from '../graphql/types';
import { type MessageRefs } from '../components/types';
import ListOldQuestions from './ListOldQuestions';
import ListSources from './ListSources';
import ListChats from './ListChats';

type ChatDrawerProps = {
  chatId?: string;
  drawerOn?: boolean;
  history?: ChatMessage[];
  itemRefs?: MessageRefs;
}

const ChatDrawerPropTypes = {
  chatId: PropTypes.string,
  drawerOn: PropTypes.bool,
  history: PropTypes.array as Validator<ChatMessage[]>,
  itemRefs: PropTypes.object as Validator<MessageRefs>
};

const ChatDrawer: FunctionComponent<ChatDrawerProps> = ({
  chatId,
  drawerOn,
  history,
  itemRefs
}) => {
  return (
    <Slide
      direction="right"
      in={Boolean(drawerOn)}
      mountOnEnter
      unmountOnExit
    >
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        overflow="scroll"
        my={0}
        px={0}
        sx={{
          minWidth: `${MIN_DRAWER}rem`,
          maxWidth: `${MIN_DRAWER}rem`,
          backgroundColor: '#fafafa',
        }}
      >
        <List sx={{ overflow: 'auto', p: 0 }}>
          <ListSources/>
          <ListChats chatId={chatId}/>
          <ListOldQuestions history={history} itemRefs={itemRefs}/>
        </List>
      </Box>
    </Slide>
  );
};

ChatDrawer.propTypes = ChatDrawerPropTypes;

export default memo(ChatDrawer);
