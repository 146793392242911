import { memo, useMemo, useCallback, type FunctionComponent } from 'react';
import PropTypes, { Validator } from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import truncate from 'lodash/truncate';
// MUI
import Box from '@mui/material/Box';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import IconButton from '@mui/material/IconButton';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import Tooltip from '@mui/material/Tooltip';
// Local
import { PATH_RELATED_VERSES, injectParams } from '../config/paths';
import { Verse } from '../graphql/types';
import style from './VerseItem.module.scss';

type VerseItemProps = {
  chat?: string,
  verse?: Verse | null;
  unclickable?: boolean;
  truncated?: boolean;
}

const VerseItemPropTypes = {
  chat: PropTypes.string,
  verse: PropTypes.object as Validator<Verse | null>,
  unclickable: PropTypes.bool,
  truncated: PropTypes.bool
};

const VerseItem: FunctionComponent<VerseItemProps> = ({
  chat,
  verse,
  unclickable,
  truncated
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const title = useMemo(() => {
    if (verse) return `${verse.book.title} ${verse.chapter.number}:${verse.number}`;
    else return '';
  }, [verse]);

  const text = useMemo(() => truncated ? truncate(verse?.text, { length: 80 }) : verse?.text, [verse, truncated]);

  const current = useMemo(() => verse?.is_current, [verse]);

  const path = useMemo(() => injectParams(
    PATH_RELATED_VERSES, {chatId: chat, verseId: verse?.id})
  , [chat, verse]);

  const handleClick = useCallback(() => {
    if (!unclickable) navigate(path);
  }, [path, navigate, unclickable]);

  return (
    <Box className={style['verse-item-body']}>
      <Box
        className={unclickable ? style['unclickable'] : style['clickable']}
        onClick={handleClick}
        sx={{
          backgroundColor: current ? 'rgba(0 0 0 / 7%)' : undefined,
          p: '0.5rem',
          pb: current ? '1.5rem' : '0.5rem'
        }}
      >
        {current && (
          <Box className={unclickable ? style['unclickable-title'] : style['title']}>
            {(unclickable && (
              <Box
                display='flex'
                alignItems='center'
              >
                <Box mr={'0.5rem'}>
                  {title}
                </Box>
                {current && (
                  <Tooltip title={t('tooltip.read.source')} arrow>
                    <IconButton>
                      <LocalLibraryIcon/>
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )) || (
              <Box display='flex' alignItems='center'>
                <Box mr={1}>
                  {title}
                </Box>
                <Box flexGrow={1}/>
                <AdsClickIcon fontSize='small' sx={{color: 'rgba(0 0 0 / 40%)'}}/>
              </Box>
            )}
          </Box>
        )}
        <Box className={style['text']}>{text}</Box>
      </Box>
    </Box>
  );
};

VerseItem.propTypes = VerseItemPropTypes;

export default memo(VerseItem);
