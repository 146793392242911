import { memo, useMemo, useEffect, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
import map from 'lodash/map';
// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
// Local
import { MIN_HISTORY } from '../config/params';
import { useApi } from '../context/ApiProvider';
import FetchFailedAlert from './FetchFailedAlert';
import Markdown from './Markdown';
import VerseCard from './VerseCard'
import VerseItem from './VerseItem'
import TabCommentaries from './TabCommentaries';
import style from './RelatedVerses.module.scss';

const RelatedVerses: FunctionComponent = () => {
  const { t } = useTranslation();
  const { chatId, verseId } = useParams();
  const {
    getVerses: { getVerses, data, failed, pending },
    getExcerpt: { getExcerpt, excerptVerses, failed: failedE, pending: pendingE },
    getCommentaries: { getCommentaries, commentaries, failed: failedC, pending: pendingC },
    getHBackground: { getHBackground, background, failed: failedB, pending: pendingB }
  } = useApi();
  const {related} = data || {};

  useEffect(() => {
    if(!isNil(verseId)) {
      getVerses?.(verseId);
      getCommentaries?.(verseId);
      getHBackground?.(verseId);
      getExcerpt?.(verseId);
    }
  }, [verseId, getVerses, getCommentaries, getHBackground, getExcerpt]);

  const commentariesList = useMemo(() => {
    if (failedC) {
      return [];
    }
    return commentaries || [];
  }, [commentaries, failedC]);

  return (
    <Box
      sx={{
        width: '100%',
        px: '1rem',
        overflow: 'auto',
        pb: '1rem',
        mb: '1rem',
        minWidth: `${MIN_HISTORY}rem`
      }}
    >
      {verseId && (
        <>
          {((failed || failedE) && <FetchFailedAlert/>) ||
           ((pending || pendingC || pendingE || pendingB) && (
            <Stack sx={{ width: '100%' }} spacing={1}>
              <LinearProgress/>
              <LinearProgress/>
              <LinearProgress/>
            </Stack>
           )) || (
            <Grid container direction='column'>
              <Grid item xs={12}>
                <Typography variant="h6" component="div" pb={'1rem'} color='rgba(0 0 0 / 70%)'>
                  {t('related.verses.title')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Box className={style['excerpts']}>
                  <Card className={style['excerpts-card']}>
                    {map(excerptVerses, (excerptVerse) => (
                      <VerseItem verse={excerptVerse} unclickable/>
                    ))}
                  </Card>
                </Box>
              </Grid>
              {(size(commentariesList) > 0) && (
                <>
                  <Grid item xs={12} sx={{maxHeight: '4rem'}}>
                    <Typography variant="h6" component="div" pb={'1rem'} color='rgba(0 0 0 / 70%)'>
                      {t('related.verses.commentaries')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} pb={'1rem'}>
                    <TabCommentaries commentaries={commentariesList}/>
                  </Grid>
                </>
              )}
              {(size(background) > 0 && !failedB) && (
                <>
                <Grid item xs={12}>
                  <Typography variant="h6" component="div" pb={'1rem'} color='rgba(0 0 0 / 70%)'>
                    {t('related.verses.background')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{pb: '1rem', width: '100%', maxWidth: '100%'}}>
                  <Card sx={{p: '1rem', height: '100%'}}>
                    <Markdown text={background?.text || ''}/>
                  </Card>
                </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Box
                  display='flex'
                  alignItems='center'
                  pb={'0.7rem'}
                >
                  <Typography variant="h6" component="div" pb={'0.3rem'} color='rgba(0 0 0 / 70%)'>
                    {t('related.verses.label')}
                  </Typography>
                  <Typography variant="subtitle2" component="div" px={'0.5rem'} color='rgba(0 0 0 / 50%)'>
                    {t('bot.verses.label.click')}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                container
                rowSpacing={1}
                columnSpacing={1}
                direction='row'
                xs={12}
              >
                {map(related, (vrse, index) => (
                  <Grid
                    item
                    key={index}
                    xs={4} sm={4} md={4}
                  >
                    <VerseCard verse={vrse} chat={chatId}/>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default memo(RelatedVerses);
