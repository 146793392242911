import { memo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
// Local imports
import Screen from '../components/Screen';
import ChatPanel from '../Panels/ChatPanel';

type ChatPageProps = {
  testSendMessage?: boolean;
}

const ChatPagePropTypes = {
  testSendMessage: PropTypes.bool
};

const ChatPage: FunctionComponent<ChatPageProps> = ({
  testSendMessage
}) => {
  return (
    <Screen>
      <ChatPanel testSendMessage={testSendMessage}/>
    </Screen>
  );
}

ChatPage.propTypes = ChatPagePropTypes;

// Default export
export default memo(ChatPage);
