import { memo, useEffect, type FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import isNil from 'lodash/isNil';
import size from 'lodash/size';
// MUI
import Box from '@mui/material/Box';
// Local imports
import { useApi } from '../context/ApiProvider';
import ChatHeader from '../components/ChatHeader';
import ChatDrawer from '../components/ChatDrawer';
import RelatedVerses from '../components/RelatedVerses';

const VersesPanel: FunctionComponent = () => {
  const { chatId } = useParams();

  const {
    getChats: { getChats, chats },
    getDrawer: { drawerOn, handleSetDrawer }
  } = useApi();

  useEffect(() => {
    if(isNil(chats)) getChats?.();
  }, [chats, getChats]);

  useEffect(() => {
    if (!isNil(chats) && !size(chats)) {
      handleSetDrawer(false);
    }
  }, [chats, handleSetDrawer]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      {/* Header */}
      <ChatHeader showVersesExplorer showShareVerse/>

      {/* Main Content Area */}
      <Box sx={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
        {/* Left Column */}
        <ChatDrawer
          chatId={chatId}
          drawerOn={drawerOn}
        />

        {/* Right Column */}
        <RelatedVerses/>
      </Box>
    </Box>
  );
}

// Default export
export default memo(VersesPanel);
